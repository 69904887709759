/* eslint-disable no-throw-literal */
import Vue from 'vue';
import FilesSender from '~/api/files-sender';
import SuluSender from '~/api/sulu-sender';

export const strict = false;

const filesSender = new FilesSender();
const suluSender = new SuluSender(true);

export const state = () => ({
  ua: '',
  mobile: false,
  weather: null,
  tablo: null,
  menu: [],
  alertMessage: null,
});

export const getters = {
  isMobile(state) {
    return state.mobile;
  },
  menu(state) {
    return state.menu;
  },
  weather(state) {
    return state.weather;
  },
  tablo(state) {
    return state.tablo;
  },
  alertMessage(state) {
    return state.alertMessage;
  },
};

export const mutations = {
  setUa(state, value) {
    state.ua = value;
  },
  setMobile(state, value) {
    state.mobile = value;
  },
  setValue(state, data) {
    Vue.set(state, data.key, data.value);
  },
  clearValue(state, data) {
    Vue.set(state, data.key, null);
  },
};

export const actions = {
  async nuxtServerInit ({ dispatch }) {
    dispatch('getWeather');
    await dispatch('getAlert');
    await dispatch('getMenu');
  },
  // eslint-disable-next-line no-empty-pattern
  sendFiles({}, formData) {
    return filesSender.post('/files/upload/ajax/multi', formData);
  },
  // eslint-disable-next-line no-empty-pattern
  sendFeedback({}, data) {
    return suluSender.post('/feedback/send', data);
  },
  getWeather({commit}) {
    Vue.prototype.$sulu.get(`weather`)
      .then(response => {
        commit('setValue', {key:'weather', value:Math.round(response.data.main.temp)});
        return Promise.resolve(response.data.main.temp);
      })
      .catch(() => {
        commit('setValue', {key:'weather', value:10});
        return Promise.resolve();
      }); 
  },
  // eslint-disable-next-line no-empty-pattern
  async getTablo({}, day) {
    const response = await Vue.prototype.$sulu.get(`flights-table?day=${day}`)
      .then(res => {
        return Promise.resolve(res.data);
      })
      .catch(e => {
        throw e;
      }); 
    return response;
  },
  async getAlert({dispatch, commit}) {
    const response = await dispatch('getData', {path:'/api/snippet-areas/alert'})
      .then(res => {
        const value = {
          id: res.id,
          description: res.content?.description,
        };
        commit('setValue', {key:'alertMessage', value});
        return Promise.resolve(res);
      })
      .catch(e => {
        throw e;
      });
    return response;
  },
  async getSchedule({dispatch}) {
    const response = await dispatch('getData', {path:'/schedule'})
      .catch(e => {
        throw e;
      });
    return response;
  },
  async getFullSchedule({dispatch}) {
    const response = await dispatch('getData', {path:'/schedule.json?showAll=1', json:false})
      .catch(e => {
        throw e;
      });
    return response;
  },
  async getNews({dispatch}, params) {
    const path = `news/${params.pathMatch}`;
    const response = await dispatch('getData', {path:`/${path}`})
      .catch(e => {
        throw e;
      });
    return response;
  },
  async getNewsList({dispatch}, page = 1) {
    const response = await dispatch('getData', {path:`/news.json?p=${page}`, json: false})
      .catch(e => {
        throw e;
      });
    return response;
  },
  async getMenu({dispatch, commit}) {
    const response = await dispatch('getData', {path:`/api/navigations/main?depth=3`})
      .then(res => {
        const menu = res._embedded 
          ? res._embedded.items.map(item => {
            return {
              name: item.title,
              items: item.children.map(child => {
                return {
                  name: child.title,
                  link: child.url,
                };
              }),
            };
          })
          : [];
        commit('setValue', {key:'menu', value: menu});
      })
      .catch(e => {
        throw e;
      });
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async getData({commit}, {path, json = true}) {
    if (typeof window !== 'undefined' && window.SULU_DATA) {
      return window.SULU_DATA;
    }
    const response = await Vue.prototype.$sulu.get(`ru${path}${json ? '.json' : ''}`)
      .then((res) => {
        if (res) {
          if (res.status === 200 && res.data.redirect) {
            throw {
              status: 301,
              url: res.data.url.replace('.json', ''),
            };
          }
          if (res.status !== 200) {
            throw {
              status: res.status,
              message: res.statusText,
            };
          }
          return res.data;
        }
        return {};
      })
      .catch(e => {
        throw e;
      });
    return response;
  },
};